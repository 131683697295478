import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

import { makeStyles } from '@material-ui/core/styles'

import useCustomCursor from '@system/hooks/useCustomCursor'

import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import PageLink from '@components/core/pagelink'
import Module from '@components/core/module'
import Slider from '@components/core/slider'

const useStyles = makeStyles((theme) => ({
  teaserCardsSliderRoot: {},
  teaserCardsItem: {
    position: 'relative',
    display: 'block',
    marginBottom: theme.spacing(6),
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  teaserCardVideoWrapper: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: (100 / 4) * 5 + '%',
  },
  teaserCardsVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
  teaserCardMedia: {
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
    '&:hover $teaserCardMediaZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
  },
  teaserCardMediaZoom: {
    pointerEvents: 'none',
    transition: 'transform 0.6s ease-out',
  },
  teaserCardDescription: {
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out',
  },
  teaserCardTagline: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  teaserCardTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  teaserCardAnimatePrev: {
    '& $teaserCardMediaZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
    '& $teaserCardDescription': {
      transform: 'translate(-16px, 0)',
      transition: 'transform 0.6s ease-in',
    },
  },
  teaserCardAnimateNext: {
    '& $teaserCardMediaZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
    '& $teaserCardDescription': {
      transform: 'translate(16px, 0)',
      transition: 'transform 0.6s ease-in',
    },
  },
}))

export type TeaserCardsSliderProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  headline?: string
  pages?: Array<TeaserCardsPageProps>
}

export type TeaserCardsPageProps = {
  id: string
  teaserTagline?: string
  teaserTitle?: string
  teaserCardImage?: DBN.Contentful.IAsset
  teaserCardVideo?: DBN.Contentful.IAsset
  fields: {
    fullPath: string
    isExternal: boolean
  }
}

export type TeaserCardsItemProps = {
  page: TeaserCardsPageProps
  animating?: string | null
}

export function TeaserCardsMedia({ page }: TeaserCardsItemProps): ReactElement {
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()
  const cursor = page.fields
    ? page.fields?.isExternal
      ? 'teaserExternal'
      : 'teaserMore'
    : ''
  const image = page.teaserCardImage ? getImage(page.teaserCardImage) : null
  const imageSRC = page.teaserCardImage ? getSrc(page.teaserCardImage) : null

  return page.teaserCardVideo ? (
    <div
      className={classes.teaserCardMedia}
      onMouseEnter={() => setCursorType(cursor)}
      onMouseLeave={() => setCursorType('')}
      onClick={() => setCursorType('')}
    >
      <div
        className={
          (classes.teaserCardVideoWrapper, classes.teaserCardMediaZoom)
        }
      >
        <video
          width="520"
          height="650"
          poster={imageSRC || ''}
          autoPlay
          muted
          loop
          playsInline
          className={classes.teaserCardsVideo}
        >
          <source
            src={page.teaserCardVideo.file?.url}
            type={page.teaserCardVideo.file?.contenttype}
          />
        </video>
      </div>
    </div>
  ) : (
    (page.teaserCardImage && image && (
      <div
        className={classes.teaserCardMedia}
        onMouseEnter={() => setCursorType(cursor)}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
      >
        <GatsbyImage
          image={image}
          alt={page.teaserCardImage.description || ''}
          title={page.teaserCardImage.title}
          className={classes.teaserCardMediaZoom}
        />
      </div>
    )) || <></>
  )
}

export function TeaserCardsItem({
  page,
  animating,
}: TeaserCardsItemProps): ReactElement {
  const classes = useStyles()

  return (
    <PageLink
      page={page}
      className={clsx(classes.teaserCardsItem, {
        [classes.teaserCardAnimatePrev]: animating == 'prev',
        [classes.teaserCardAnimateNext]: animating == 'next',
      })}
    >
      <TeaserCardsMedia page={page} className={classes.teaserCardMedia} />
      <div className={classes.teaserCardDescription}>
        {page.teaserTagline && (
          <Paragraph className={classes.teaserCardTagline}>
            {page.teaserTagline}
          </Paragraph>
        )}
        {page.teaserTitle && (
          <Headline level={3} className={classes.teaserCardTitle}>
            {page.teaserTitle}
          </Headline>
        )}
      </div>
    </PageLink>
  )
}

export default function TeaserCardsSlider({
  theme,
  anchor,
  headline,
  pages,
}: TeaserCardsSliderProps): ReactElement {
  const classes = useStyles()

  return pages && pages.length ? (
    <Module
      theme={theme}
      anchor={anchor}
      className={classes.teaserCardsSliderRoot}
    >
      <Slider headline={headline}>
        {pages &&
          pages?.map((page: TeaserCardsPageProps) => (
            <TeaserCardsItem key={page.id} page={page} />
          ))}
      </Slider>
    </Module>
  ) : (
    <></>
  )
}
