import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Snackbar, Typography, Button } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import Container from '@components/modules/global/container'
import Icon from '@components/core/icon'
import FontSize from '@config/theme/definitions/fontSize'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  notificationRoot: {
    [theme.breakpoints.up('md')]: {},
  },
  notificationBar: {
    width: '100vw',
    top: 0,
    left: 0,
    position: 'fixed',
    transform: 'none',
    display: 'block',
    background: '#0000DB',
  },
  notificationContentWrapper: {},
  notificationContent: {
    display: 'flex',
    justifyContent: 'space-between',

    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  copy: {
    fontSize: FontSize['lg'],
    lineHeight: FontSize['xl'],
  },
  closeButton: {
    paddingLeft: '80px',
    display: 'block',
    '&svg': {
      width: '24px',
      height: '24px',
    },
  },
  link: {},
}))

export type INotificationProps = {
  show?: boolean
  children?: React.ReactChild | React.ReactNode | string
  onClose?: (event: React.ChangeEvent<any>, value: number | number[]) => void
}

export default function Notification({
  show,
  children,
  onClose,
}: INotificationProps): ReactElement {
  const classes = useStyles()

  return (
    <div className={clsx(classes.notificationRoot)}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={show}
        autoHideDuration={6000}
        onClose={onClose}
        className={classes.notificationBar}
      >
        <Container
          type="nomargin"
          className={classes.notificationContentWrapper}
        >
          <div className={classes.notificationContent}>
            <Typography className={classes.copy} variant="body1" component="p">
              {children}
            </Typography>
            <a className={classes.closeButton} onClick={onClose}>
              <Icon name="Close" color="inherit" />
            </a>
          </div>
        </Container>
      </Snackbar>
    </div>
  )
}
