import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { makeStyles, Theme } from '@material-ui/core/styles'

import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles<Theme, QuoteProps>((theme) => ({
  blockquote: {
    padding: 0,
    margin: 0,
  },
  blockquoteText: ({ fontsize }) => ({
    fontFamily: theme.typography.fontFamily,
    margin: 0,
    fontSize: '48px',
    lineHeight: 1.1667,
    [theme.breakpoints.up('md')]: {
      fontSize: fontsize ? fontsize : FontSize['6xl'],
      lineHeight: 1.5714,
      margin: 0,
    },
  }),
  blockquoteTextCite: {
    '&:before': {
      content: '"„"',
    },
    '&:after': {
      content: '"“"',
    },
  },
  blockquoteFooter: {
    marginTop: theme.spacing(10),
    maxWidth: '336px',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(14),
    },
  },
  blockquoteAuthor: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSize['2lg'],
    lineHeight: 1.25,
    fontStyle: 'normal',
  },
  blockquotePosition: {
    display: 'block',
    marginTop: theme.spacing(2),
    fontFamily: theme.typography.fontFamily2,
    fontSize: FontSize['base'],
    lineHeight: 2,
    fontStyle: 'normal',
  },
}))

export type QuoteProps = {
  text: string
  author?: string
  position?: string
  className?: string
  fontsize?: string
}

export default function Blockquote({
  text,
  author,
  position,
  className,
  fontsize,
}: QuoteProps): ReactElement {
  const classes = useStyles({ fontsize })
  return (
    <blockquote className={clsx(className, classes.blockquote)}>
      <p
        className={clsx(classes.blockquoteText, {
          [classes.blockquoteTextCite]: author,
        })}
      >
        {text}
      </p>
      {author && (
        <footer className={classes.blockquoteFooter}>
          <cite className={classes.blockquoteAuthor}>{author}</cite>
          {position && (
            <span className={classes.blockquotePosition}>{position}</span>
          )}
        </footer>
      )}
    </blockquote>
  )
}
