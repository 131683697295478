// container width + 32px padding (l+r)

import { Theme } from '@material-ui/core/styles/createTheme'

const Container: Theme['container'] = {
  sm: '736px',
  md: '920px',
  lg: '1152px',
}

export default Container
