import { Theme } from '@material-ui/core'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import FontSize from './definitions/fontSize'

export default function TypographyOverrides(theme: Theme): TypographyOptions {
  return {
    body1: {
      fontFamily: theme.typography.fontFamily2,
      fontSize: FontSize['base'],
      lineHeight: 2,
    },
    body2: {
      fontFamily: theme.typography.fontFamily2,
      fontSize: FontSize['base'],
      lineHeight: 2,
    },
    h1: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['4xl'],
      letterSpacing: '-2px',
      lineHeight: 1.2,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('md')]: {
        fontSize: FontSize['7xl'],
      },
    },
    h2: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['3xl'],
      letterSpacing: '-2px',
      lineHeight: 1.56,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        fontSize: FontSize['5xl'],
      },
    },
    h2bg: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['4xl'],
      letterSpacing: '-2px',
      lineHeight: 1.6,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        fontSize: FontSize['6xl'],
        lineHeight: 1.3571,
      },
    },
    h3: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['xl'],
      lineHeight: 1.3,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        fontSize: FontSize['xl'],
      },
    },
    h4: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['2lg'],
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        fontSize: FontSize['2lg'],
      },
    },
    sliderbg: {
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '-2px',
      lineHeight: 0.96,
      fontSize: FontSize['9xl'],
      [theme.breakpoints.down('md')]: {
        fontSize: FontSize['8xl'],
      },
    },
    subtitle1: {
      fontFamily: theme.typography.fontFamily1,
      fontSize: FontSize['xl'],
      lineHeight: 1.5385,
      [theme.breakpoints.up('md')]: {
        fontSize: FontSize['2xl'],
      },
    },
    infotext: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['base'],
      lineHeight: 1,
      color: theme.palette.text.tertiary,
    },
    introtext: {
      fontFamily: theme.typography.fontFamily1,
      fontSize: FontSize['lg'],
      lineHeight: 1,
    },
    introsubline: {
      fontFamily: theme.typography.fontFamily1,
      fontSize: FontSize['2xl'],
      lineHeight: 1.5385,
    },
    intrometahead: {
      fontFamily: theme.typography.fontFamily2,
      fontSize: FontSize['base'],
      lineHeight: 2,
    },
    intrometabody: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['2lg'],
      lineHeight: 1.2,
    },
    introtext2: {
      fontFamily: theme.typography.fontFamily1,
      fontSize: FontSize['lg'],
      lineHeight: 1.3334,
    },
    caption: {
      fontSize: FontSize['sm'],
      lineHeight: 1.14,
      color: theme.palette.text.secondary,
      fontStyle: 'normal',
    },
    link: {
      color: theme.palette.text.highlight,
      '&:focus': {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
      },
      '&:hover': {
        color: theme.palette.error.light,
        textDecoration: 'none',
      },
    },
    navigation: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['sm'],
      lineHeight: 1.4286,
    },
    navigationSecondary: {
      [theme.breakpoints.up('xl')]: {
        fontFamily: theme.typography.fontFamily2,
        fontSize: FontSize['2lg'],
        lineHeight: 1.3334,
      },
    },
    navigationDrawer: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['lg'],
      lineHeight: 1.5556,
    },
    navigationMeta: {
      fontFamily: theme.typography.fontFamily2,
    },
    breadcrumb: {
      lineHeight: 1.625,
    },
    buttonPrimary: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['lg'],
      lineHeight: 1.1111,
    },
    buttonSecondary: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['lg'],
      lineHeight: 1.1111,
    },
    buttonText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['lg'],
      lineHeight: 1.1111,
    },
    footernavigation: {
      fontFamily: theme.typography.fontFamily1,
      fontSize: FontSize['xs'],
      lineHeight: 1.3334,
    },
    helpertext: {
      fontSize: FontSize['xs'],
      lineHeight: 1.125,
    },
    input: {
      lineHeight: 1.25,
    },
    label: {
      fontSize: FontSize['xs'],
      lineHeight: 1.278,
    },
    checkboxOption: {
      fontSize: FontSize['lg'],
    },
    toc: {
      lineHeight: 1.5,
      fontSize: FontSize['base'],
    },
    teaser: {
      fontSize: FontSize['sm'],
      color: theme.palette.text.teaser,
    },
    pagination: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['2lg'],
      lineHeight: 1.25,
    },
  }
}
