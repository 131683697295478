import React, { KeyboardEvent, useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { useIntl, FormattedMessage } from 'react-intl'
import Container from '@components/modules/global/container'
import Icon from '@components/core/icon'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import TextField from '@components/core/formfields/textField'
import TextArea from '@components/core/formfields/textArea'
import SelectBox from '@components/core/formfields/selectBox'
import FileUpload from '@components/core/formfields/fileUpload'
import Button from '@components/core/button'
import Headline from '@components/text/headline'
import Link from '@components/core/link'
import { Typography } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import FontSize from '@config/theme/definitions/fontSize'
import Notification from '../notification'
import useGlobalText from '@system/hooks/useGlobalText'
import Module from '@components/core/module'

interface IApplicationFormProps {
  job?: {
    department?: string
    id?: string
    jobDescriptions?: Array<{ name: string; value: string }>
    jobId?: string
    name?: string
    office: null
    slug?: string
    unit?: string
  }
}

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    jobTitle: {
      marginBottom: theme.spacing(14),
    },
    jobSubline: {
      marginBottom: theme.spacing(10),
    },
    form: {
      maxWidth: '520px',
      marginBottom: theme.spacing(24),
    },
    headlineFirst: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
    headline: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6),
    },
    pageRoot: {
      marginTop: theme.spacing(27),
      paddingTop: theme.spacing(10),
    },
    requiredLabel: {
      ...theme.typography.label,
      padding: theme.spacing(0, 5, 1, 0),
      color: 'inherit',
    },
    lockIcon: {
      width: '25px',
      height: '24px',
      marginRight: theme.spacing(2),
      marginBottom: '6px',
    },
    submitButton: {
      marginTop: theme.spacing(8),
    },
    successContainer: {},
    successHeadline: {
      marginBottom: theme.spacing(16),
    },
    successCopy: {
      maxWidth: '520px',
      marginBottom: theme.spacing(12),
    },
    successButton: {
      maxWidth: '520px',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: FontSize['xl'],
    },
  })
)

export default function ApplicationForm({
  job,
}: IApplicationFormProps): React.ReactElement {
  const classes = useStyles()
  const intl = useIntl()
  const { getText } = useGlobalText()
  const [step, setStep] = useState('form')
  const [showNotification, setShowNotification] = useState(false)
  const [channel, setChannel] = useState('')
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [channelList, setChannelList] = useState<
    Array<{ value: string; text: string }>
  >([])

  const onSelectChange = (value: string) => {
    setChannel(value)
  }

  const phoneRegex = /^(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))$/

  const validationSchema = () =>
    Yup.object({
      first_name: Yup.string().required(
        intl.formatMessage({ id: 'form.error.required' })
      ),
      last_name: Yup.string().required(
        intl.formatMessage({ id: 'form.error.required' })
      ),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'form.error.email.format' }))
        .required(intl.formatMessage({ id: 'form.error.required' })),
      // text: Yup.string().required(
      //   intl.formatMessage({ id: 'contact.text.error.required' })
      // ),
      phone: Yup.string().matches(
        phoneRegex,
        intl.formatMessage({ id: 'form.error.phone.format' })
      ),
      available_from: Yup.string().required(
        intl.formatMessage({ id: 'form.error.required' })
      ),
      salary_expectations: Yup.string(),
    })

  const scrollToError = (id: string) => {
    if (window) {
      const rect = document.getElementById(id).getBoundingClientRect()
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      window.scrollTo(0, rect.top + scrollTop - 80)
    }
  }

  const onSubmitHandler = async (values, actions) => {
    setLoading(true)
    const {
      email,
      phone,
      first_name,
      last_name,
      message,
      salary_expectations,
      available_from,
      website,
    } = values

    const data = {
      job_id: job?.jobId,
      available_from,
      email,
      phone,
      first_name,
      channel,
      last_name,
      message,
      salary_expectations,
      website,
    }

    const headers = {
      'X-Token': token,
    }

    await axios
      .post(
        `${process.env.GATSBY_APPLICATION_SERVICE_URL}/api/application/create`,
        data,
        { headers }
      )
      .then(() => {
        setStep('success')
        cleanup()
        if (window) {
          setTimeout(() => {
            window.scrollTo(0, 0)
          }, 500)
        }
      })
      .catch((e) => handleRequestError(e, actions))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRequestError = (error: AxiosError, actions) => {
    switch (error.response.status) {
      case 400:
        const { parameterViolations } = error.response.data
        const errors = parameterViolations.map((item) => {
          return {
            id: item.path.split('apply.application.').join(''),
            code: item.message,
            message: intl.formatMessage({
              id: 'form.error.backend.common',
            }),
          }
        })

        const err = {}
        for (let i = 0; i < errors.length; i++) {
          const id = errors[i].id
            // .replace(/((?<=[a-z\d])[A-Z]|(?<=[A-Z\d])[A-Z](?=[a-z]))/g, '_$1')
            // .toLowerCase()
            .replace(/([A-Z])/g, (letter: string) => `_${letter.toLowerCase()}`)
          if (id) err[id] = errors[i].message
        }

        try {
          actions.setErrors(err)
        } catch (e) {}

        if (errors.length > 0) {
          scrollToError(errors[0].id)
        }
        break
      case 409:
        setStep('duplicate')
        cleanup()
        if (window) {
          setTimeout(() => {
            window.scrollTo(0, 0)
          }, 500)
        }
        break
      case 403:
        setStep('token')
        cleanup()
        if (window) {
          setTimeout(() => {
            window.scrollTo(0, 0)
          }, 500)
        }
        break
      default:
        setStep('error')
        cleanup()
        if (window) {
          setTimeout(() => {
            window.scrollTo(0, 0)
          }, 500)
        }
    }
  }

  const cleanup = () => {
    sessionStorage.removeItem('sff-applicationID')
    axios.delete(
      `${process.env.GATSBY_APPLICATION_SERVICE_URL}/api/token/${token}`
    )
  }
  useEffect(() => {
    if ((job && job.jobId) || (job && job.id)) {
      const sffJobApplication = localStorage.getItem('sff-job-application')
      const config =
        sffJobApplication !== null ? JSON.parse(sffJobApplication) : {}
      if (
        job?.jobId &&
        Number(job.jobId) in config &&
        Date.parse(config[Number(job.jobId)].validUntil) > Date.now()
      ) {
        setToken(config[job.jobId].id)
      } else {
        axios
          .post(`${process.env.GATSBY_APPLICATION_SERVICE_URL}/api/token`)
          .then((response) => {
            if (job.jobId) {
              config[job.jobId] = response.data
              localStorage.setItem(
                'sff-job-application',
                JSON.stringify(config)
              )
              setToken(config[job.jobId].id)
            }
          })
          .catch((e) => {
            console.error(e)
          })
      }

      axios
        .get(`${process.env.GATSBY_APPLICATION_SERVICE_URL}/api/model/channel`)
        .then((response) => {
          const channelArray = []
          for (const item in response.data) {
            channelArray.push({
              value: item,
              text: getText(`job.application.channel.${item}`) || item,
            })
          }
          setChannelList(channelArray)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [])

  const showNotificationHandler = () => {
    const ssfJobNotification = localStorage.getItem('sff-job-notification')
    setShowNotification(ssfJobNotification !== 'yes')
    localStorage.setItem('sff-job-notification', 'yes')
  }

  switch (step) {
    case 'form':
      return (
        <Module theme="light" className={classes.pageRoot}>
          <Container className={classes.container} type="nomargin">
            <Notification
              show={showNotification}
              onClose={() => setShowNotification(false)}
            >
              {getText('module.jobApplication.notification') ||
                'application is saved for 2 days. Finish it now or come back later.'}
            </Notification>
            {job && (
              <Headline className={classes.jobTitle} level={1}>
                {job.name}
              </Headline>
            )}
            <Headline className={classes.jobSubline} level={2}>
              {intl.formatMessage({
                id: 'form.application.headline.submit',
              })}
            </Headline>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                website: '',
                available_from: '',
                channel: '',
                salary_expectations: '',
                message: '',
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
              validateOnBlur={false}
            >
              <Form id="voting" className={classes.form}>
                <InputLabel className={classes.requiredLabel}>
                  *{intl.formatMessage({ id: 'form.label.required' })}
                </InputLabel>

                <div>
                  <Headline className={classes.headlineFirst} level={3}>
                    {intl.formatMessage({
                      id: 'form.application.headline.contact',
                    })}
                  </Headline>

                  <TextField
                    id="firstName"
                    type="text"
                    name="first_name"
                    error={false}
                    label={
                      intl.formatMessage({
                        id: 'form.application.label.first_name',
                      }) + '*'
                    }
                    onBlur={showNotificationHandler}
                  />
                  <TextField
                    id="lastName"
                    type="text"
                    name="last_name"
                    error={false}
                    label={
                      intl.formatMessage({
                        id: 'form.application.label.last_name',
                      }) + '*'
                    }
                    onBlur={showNotificationHandler}
                  />

                  <TextField
                    id="email"
                    type="email"
                    name="email"
                    error={false}
                    label={
                      intl.formatMessage({
                        id: 'form.application.label.email',
                      }) + '*'
                    }
                    onBlur={showNotificationHandler}
                  />

                  <TextField
                    id="phone"
                    type="text"
                    name="phone"
                    error={false}
                    label={intl.formatMessage({
                      id: 'form.application.label.phone',
                    })}
                    onBlur={showNotificationHandler}
                  />

                  <TextField
                    id="website"
                    type="text"
                    name="website"
                    error={false}
                    label={intl.formatMessage({
                      id: 'form.application.label.website',
                    })}
                    onBlur={showNotificationHandler}
                  />
                </div>
                <div>
                  <Headline className={classes.headline} level={3}>
                    {intl.formatMessage({
                      id: 'form.application.headline.entryandsalary',
                    })}
                  </Headline>

                  <TextField
                    id="availableFrom"
                    type="date"
                    name="available_from"
                    error={false}
                    label={
                      intl.formatMessage({
                        id: 'form.application.label.available_from',
                      }) + '*'
                    }
                    onBlur={showNotificationHandler}
                  />

                  <TextField
                    id="salaryExpectations"
                    type="number"
                    name="salary_expectations"
                    error={false}
                    label={intl.formatMessage({
                      id: 'form.application.label.salary_expectations',
                    })}
                    onBlur={showNotificationHandler}
                    onKeyDown={(event: KeyboardEvent) => {
                      if (
                        (event.keyCode < 48 || event.keyCode > 57) &&
                        (event.keyCode < 96 || event.keyCode > 105) &&
                        event.keyCode != 8 &&
                        event.keyCode != 37 &&
                        event.keyCode != 38 &&
                        event.keyCode != 46
                      ) {
                        event.preventDefault()
                      }
                    }}
                  />
                </div>

                <div>
                  <Headline className={classes.headline} level={3}>
                    {intl.formatMessage({
                      id: 'form.application.headline.upload',
                    })}
                  </Headline>
                  {token && (
                    <FileUpload
                      accept="image/jpeg,image/gif,image/png,application/pdf"
                      token={token}
                      max={60}
                      maxFile={20}
                      multiple
                    />
                  )}
                </div>

                <div>
                  <Headline className={classes.headline} level={3}>
                    {intl.formatMessage({
                      id: 'form.application.headline.somethingmore',
                    })}
                  </Headline>
                  <TextArea
                    id="message"
                    type="text"
                    name="message"
                    error={false}
                    label={intl.formatMessage({
                      id: 'form.application.label.message',
                    })}
                  />
                </div>

                {channelList && (
                  <div>
                    <Headline className={classes.headline} level={3}>
                      {intl.formatMessage({
                        id: 'form.application.headline.lastquestion',
                      })}
                    </Headline>
                    <SelectBox
                      id="channel"
                      name="channel"
                      label={intl.formatMessage({
                        id: 'form.application.label.channel',
                      })}
                      error={false}
                      className={classes.select}
                      onChange={onSelectChange}
                      items={channelList}
                    />
                  </div>
                )}

                <div>
                  <Headline level={4}>
                    <Icon name="Lock" className={classes.lockIcon} />
                    {intl.formatMessage({
                      id: 'form.application.headline.savedata',
                    })}
                  </Headline>
                  <Typography variant="body1" component="p">
                    <FormattedMessage
                      id="form.application.copy.savedata"
                      values={{
                        a: function terms(terms: string) {
                          return (
                            <Link
                              className={classes.link}
                              to={intl.formatMessage({
                                id: 'form.application.dataprivacy.path',
                              })}
                              isExternal={true}
                            >
                              {terms}
                            </Link>
                          )
                        },
                      }}
                    />
                  </Typography>
                </div>

                <Button
                  className={classes.submitButton}
                  formSubmit={true}
                  disabled={loading}
                >
                  {intl.formatMessage({
                    id: 'form.application.button.submit',
                  })}
                </Button>
              </Form>
            </Formik>
          </Container>
        </Module>
      )
    case 'success':
      return (
        <Module theme="light" className={classes.pageRoot}>
          <Container className={classes.successContainer}>
            <Headline level={1} className={classes.successHeadline}>
              {getText('form.application.success.headline') ||
                'Thanks for your application!'}
            </Headline>
            <Typography
              variant="h2"
              component="p"
              className={classes.successCopy}
            >
              {getText('form.application.success.copy') ||
                'You should receive a confirmation mail shortly. We will get in contact with you, when we had a look on your application.'}
            </Typography>
            <Link
              className={classes.successButton}
              to="/"
              icon={{ name: 'ArrowTeaser', size: 'small' }}
            >
              {getText('form.application.success.button') || 'Back to home'}
            </Link>
          </Container>
        </Module>
      )
    case 'duplicate':
      return (
        <Module theme="light" className={classes.pageRoot}>
          <Container className={classes.successContainer}>
            <Headline level={1} className={classes.successHeadline}>
              {getText('form.application.duplicate.headline') ||
                'Oops, something went wrong!'}
            </Headline>
            <Typography
              variant="h2"
              component="p"
              className={classes.successCopy}
            >
              {getText('form.application.duplicate.copy') ||
                'It seems like that you already send an appointment for this job.'}
            </Typography>
            <Link
              className={classes.successButton}
              to="/"
              icon={{ name: 'ArrowTeaser', size: 'small' }}
            >
              {getText('form.application.success.button') || 'Back to home'}
            </Link>
          </Container>
        </Module>
      )
    case 'token':
      return (
        <Module theme="light" className={classes.pageRoot}>
          <Container className={classes.successContainer}>
            <Headline level={1} className={classes.successHeadline}>
              {getText('form.application.token.headline') ||
                'Oops, something went wrong!'}
            </Headline>
            <Typography
              variant="h2"
              component="p"
              className={classes.successCopy}
            >
              {getText('form.application.token.copy') ||
                'It seems like you really think about your application. In the meantime, your personal token has expired. Please reload the page to retrieve an new one.'}
            </Typography>
            <Link
              className={classes.successButton}
              to="/"
              icon={{ name: 'ArrowTeaser', size: 'small' }}
            >
              {getText('form.application.success.button') || 'Back to home'}
            </Link>
          </Container>
        </Module>
      )
    case 'error':
      return (
        <Module theme="light" className={classes.pageRoot}>
          <Container className={classes.successContainer}>
            <Headline level={1} className={classes.successHeadline}>
              {getText('form.application.error.headline') ||
                'Oops, something went wrong!'}
            </Headline>
            <Typography
              variant="h2"
              component="p"
              className={classes.successCopy}
            >
              {getText('form.application.error.copy') ||
                'An unknown error has occurred. Please try again later.'}
            </Typography>
            <Link
              className={classes.successButton}
              to="/"
              icon={{ name: 'ArrowTeaser', size: 'small' }}
            >
              {getText('form.application.success.button') || 'Back to home'}
            </Link>
          </Container>
        </Module>
      )
    default:
      return <React.Fragment></React.Fragment>
  }
}
