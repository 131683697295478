import { HistoryLocation } from 'reach__router'
import Intro, { IntroProps } from '@components/modules/content/M007-Intro'

import CaseIntro, {
  CaseIntroProps,
} from '@components/modules/content/M002-CaseIntro'
import Image, { ImageProps } from '@components/modules/content/M025-Image'
import Quote, { QuoteProps } from '@components/text/quote'
import Stage, { StageProps } from '@components/modules/content/M015-Stage'
import Teaser, { TeaserProps } from '@components/modules/content/M017-Teaser'
import TeaserCards, {
  TeaserCardsProps,
} from '@components/modules/content/M018-TeaserCards'
import TeaserCardsSlider, {
  TeaserCardsSliderProps,
} from '@components/modules/content/M019-TeaserCardsSlider'
import Text, { TextProps } from '@components/modules/content/M023-TextModule'
import TextImage, {
  TextImageProps,
} from '@components/modules/content/M022-TextImage'
import Gallery, { GalleryProps } from '@components/modules/content/M006-Gallery'
import Contact, { ContactProps } from '@components/modules/content/M003-Contact'
import NextCase, {
  NextCaseProps,
} from '@components/modules/content/M013-NextCase'
import VerticalSpacer, { VerticalSpacerProps } from '@components/core/spacer'
import Video, { VideoProps } from '@components/modules/content/M024-VideoModule'
import LogoWall, {
  LogoWallProps,
} from '@components/modules/content/M011-LogoWall'
import FamilyHeads, {
  FamilyHeadsProps,
} from '@components/modules/content/M004-FamilyHeads'
import TeaserFull, {
  TeaserFullProps,
} from '@components/modules/content/M020-TeaserFull'
import TeaserText, {
  TeaserTextProps,
} from '@components/modules/content/M021-TeaserText'
import FixedContent, {
  FixedContentProps,
} from '@components/modules/content/M005-FixedContent'
import Headline, {
  HeadlineProps,
} from '@components/modules/content/M026-Headline'
import Awards, { AwardsProps } from '@components/modules/content/M001-Awards'

type ModulePropsUnion =
  | IntroProps
  | CaseIntroProps
  | ImageProps
  | QuoteProps
  | DBN.IReactDefaultProps
  | StageProps
  | TeaserCardsProps
  | TeaserCardsSliderProps
  | TeaserProps
  | TextImageProps
  | TextProps
  | GalleryProps
  | VideoProps
  | LogoWallProps
  | FamilyHeadsProps
  | TeaserFullProps
  | TeaserTextProps
  | FixedContentProps
  | HeadlineProps
  | AwardsProps
  | ContactProps
  | NextCaseProps
  | VerticalSpacerProps

type PageModuleParserType = {
  __typename: string
  component: React.ElementType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: (
    mod: any,
    location?: HistoryLocation,
    pageContext?: DBN.PageHelpers.PageContext
  ) => ModulePropsUnion
}

const PageModuleParser: Array<PageModuleParserType> = [
  {
    __typename: 'ContentfulModuleStage',
    component: Stage,
    props: (mod: DBN.Contentful.IModuleStage): StageProps => {
      return {
        titleInternal: mod.titleInternal,
        anchor: mod.anchor,
        type: mod.type,
        headline: mod.headline,
        headline2: mod.headline2,
        media: mod.media,
        mediaMobile: mod.mediaMobile,
        fallbackImage: mod.fallbackImage,
      }
    },
  },
  {
    __typename: 'ContentfulModuleImage',
    component: Image,
    props: (mod: DBN.Contentful.IModuleImage): ImageProps => {
      return mod
    },
  },
  {
    __typename: 'ContentfulModuleTextImage',
    component: TextImage,
    props: (mod: DBN.Contentful.IModuleTextImage): TextImageProps => {
      return {
        titleInternal: mod.titleInternal,
        theme: mod.theme,
        anchor: mod.anchor,
        type: mod.type,
        headline: mod.headline,
        copy: mod.copy,
        link: mod.link,
        image: mod.image,
      }
    },
  },
  {
    __typename: 'ContentfulModuleIntro',
    component: Intro,
    props: (mod: DBN.Contentful.IModuleIntro): IntroProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        copy: mod.copy,
      }
    },
  },
  {
    __typename: 'ContentfulModuleCaseIntro',
    component: CaseIntro,
    props: (mod: DBN.Contentful.IModuleCaseIntro): CaseIntroProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        headline: mod.headline,
        subline: mod.subline,
        copy: mod.copy,
        logo: mod.logo,
        agency: mod.agency,
        year: mod.year,
        awards: mod.awards,
      }
    },
  },
  {
    __typename: 'ContentfulModuleQuote',
    component: Quote,
    props: (mod: DBN.Contentful.IModuleQuote): QuoteProps => {
      return mod
    },
  },
  {
    __typename: 'ContentfulModuleText',
    component: Text,
    props: (mod: DBN.Contentful.IModuleText): TextProps => {
      return {
        titleInternal: mod.titleInternal,
        theme: mod.theme,
        layout: mod.layout,
        anchor: mod.anchor,
        tagline: mod.tagline,
        headline: mod.headline,
        headline2: mod.headline2,
        subline: mod.subline,
        copy: mod.copy,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaser',
    component: Teaser,
    props: (mod: DBN.Contentful.IModuleTeaser): TeaserProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        type: mod.type,
        headline: mod.headline,
        rows: mod.rows,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserCards',
    component: TeaserCards,
    props: (mod: DBN.Contentful.IModuleTeaserCards): TeaserCardsProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        align: mod.align,
        headline: mod.headline,
        pages: mod.pages,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserCardsSlider',
    component: TeaserCardsSlider,
    props: (
      mod: DBN.Contentful.IModuleTeaserCardsSlider
    ): TeaserCardsSliderProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        headline: mod.headline,
        pages: mod.pages,
      }
    },
  },
  {
    __typename: 'ContentfulModuleNextCase',
    component: NextCase,
    props: (mod: DBN.Contentful.IModuleNextCase): NextCaseProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        teaserTagline: mod.teaserTagline,
        casePage: mod.casePage,
      }
    },
  },
  {
    __typename: 'ContentfulModuleVerticalSpacer',
    component: VerticalSpacer,
    props: (mod: DBN.Contentful.IModuleVerticalSpacer): VerticalSpacerProps => {
      return {
        theme: mod.theme,
        sizeDesktop: mod.sizeDesktop,
        sizeMobile: mod.sizeMobile,
      }
    },
  },
  {
    __typename: 'ContentfulModuleGallery',
    component: Gallery,
    props: (mod: DBN.Contentful.IModuleGallery): GalleryProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        headline: mod.headline,
        images: mod.images,
      }
    },
  },
  {
    __typename: 'ContentfulModuleContact',
    component: Contact,
    props: (
      mod: DBN.Contentful.IModuleContact,
      location: HistoryLocation,
      pageContext: DBN.PageHelpers.PageContext
    ): ContactProps => {
      return {
        pageContext,
        theme: mod.theme,
        anchor: mod.anchor,
        type: mod.type,
        titleInternal: mod.titleInternal,
        headline: mod.headline,
        sublineCopy: mod.sublineCopy,
        copy: mod.copy,
        socialNetwork: mod.socialNetwork,
      }
    },
  },
  {
    __typename: 'ContentfulModuleVideo',
    component: Video,
    props: (mod: DBN.Contentful.IModuleVideo): VideoProps => {
      return {
        anchor: mod.anchor,
        youtubeId: mod.youtubeId,
        poster: mod.poster,
        posterMobile: mod.posterMobile,
      }
    },
  },
  {
    __typename: 'ContentfulModuleLogoWall',
    component: LogoWall,
    props: (mod: DBN.Contentful.IModuleLogoWall): LogoWallProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        headline: mod.headline,
        clients: mod.clients,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFamilyHeads',
    component: FamilyHeads,
    props: (mod: DBN.Contentful.IModuleFamilyHeads): FamilyHeadsProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        headline: mod.headline,
        copy: mod.copy,
        members: mod.members,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserFull',
    component: TeaserFull,
    props: (mod: DBN.Contentful.IModuleTeaserFull): TeaserFullProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        align: mod.align,
        page: mod.page,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserText',
    component: TeaserText,
    props: (mod: DBN.Contentful.IModuleTeaserText): TeaserTextProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        slider: mod.slider,
        headline: mod.headline,
        pages: mod.pages,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFixedContent',
    component: FixedContent,
    props: (
      mod: DBN.Contentful.IModuleFixedContent,
      location: HistoryLocation,
      pageContext: DBN.PageHelpers.PageContext
    ): FixedContentProps => {
      return {
        pageContext: pageContext,
        theme: mod.theme,
        anchor: mod.anchor,
        element: mod.element,
      }
    },
  },
  {
    __typename: 'ContentfulModuleHeadline',
    component: Headline,
    props: (mod: DBN.Contentful.IModuleHeadline): HeadlineProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        headline: mod.headline,
      }
    },
  },
  {
    __typename: 'ContentfulModuleAwards',
    component: Awards,
    props: (mod: DBN.Contentful.IModuleAwards): AwardsProps => {
      return {
        theme: mod.theme,
        anchor: mod.anchor,
        awardElement: mod.awardElement,
      }
    },
  },
]

export default PageModuleParser
