import CircularMediumWoff from '@static/font/CircularXXWeb-Medium.woff'
import CircularMediumWoff2 from '@static/font/CircularXXWeb-Medium.woff2'
import CircularBookWoff from '@static/font/CircularXXWeb-Book.woff'
import CircularBookWoff2 from '@static/font/CircularXXWeb-Book.woff2'
import CircularBoldWoff from '@static/font/CircularXXWeb-Bold.woff'
import CircularBoldWoff2 from '@static/font/CircularXXWeb-Bold.woff2'

const CircularMedium = {
  fontFamily: 'Circular Medium',
  src: `
    url(${CircularMediumWoff2}) format('woff2'),
    url(${CircularMediumWoff}) format('woff')
  `,
}

const CircularBook = {
  fontFamily: 'Circular Book',
  src: `
  url(${CircularBookWoff2}) format('woff2'),
  url(${CircularBookWoff}) format('woff')
  `,
}

const CircularBold = {
  fontFamily: 'Circular Bold',
  src: `
  url(${CircularBoldWoff2}) format('woff2'),
  url(${CircularBoldWoff}) format('woff')
  `,
}

export default [CircularMedium, CircularBook, CircularBold]
