import React, { ReactElement, useState, useEffect } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { TextFieldProps } from '@material-ui/core/TextField'
import useCustomCursor from '@system/hooks/useCustomCursor'
import { useIntl } from 'react-intl'
import FormHelperText from './helperText'
import Icon from '@components/core/icon'
import { Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  '@keyframes sk-rotateplane': {
    '0%': {
      transform: 'perspective(120px) rotateX(0deg) rotateY(0deg)',
      '-webkit-transform': 'perspective(120px) rotateX(0deg) rotateY(0deg)',
    },
    '50%': {
      transform: 'perspective(120px) rotateX(-180.1deg) rotateY(0deg)',
      '-webkit-transform':
        'perspective(120px) rotateX(-180.1deg) rotateY(0deg)',
    },
    '100%': {
      transform: 'perspective(120px) rotateX(-180deg) rotateY(-179.9deg)',
      '-webkit-transform':
        'perspective(120px) rotateX(-180deg) rotateY(-179.9deg)',
    },
  },
  inputUploadRoot: {},
  input: {},
  iconMore: {
    width: '48px',
    height: '48px',
    overflow: 'hidden',
    backgroundColor: '#FF2001',
    boxSizing: 'border-box',
    borderRadius: '100%',
    padding: '20px',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    color: theme.palette.button.mainText,
    '& svg': {
      position: 'absolute',
      left: '17px',
      top: '17px',
      width: '20px',
      height: '20px',
    },
    '@media (hover: hover)': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '0',
        padding: '0 0 100% 0',
        margin: '-50%',
        backgroundColor: 'black',
        borderRadius: '50%',
        opacity: 0,
        transform: 'scale(0.01)',
        transition: 'transform 0.6s, opacity 0.2s 0.4s',
      },
      '&:hover': {
        textDecoration: 'none',
        '&::before': {
          opacity: 1,
          transform: 'scale(2)',
          transition: 'transform 0.6s, opacity 0.2s',
        },
      },
    },
  },
  loading: {
    width: '48px',
    height: '48px',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    backgroundColor: '#FF2001',
    margin: '0px 0px',
    animation: '$sk-rotateplane 1.2s infinite ease-in-out',
  },
  label: {
    display: 'block',
    cursor: 'none',
    marginBottom: theme.spacing(8),
  },

  info: {
    display: 'inline-block',
    verticalAlign: 'text-top',
    width: '250px',
    marginLeft: theme.spacing(4),
    '& :first-child': {
      marginBottom: 0,
    },
    '& :nth-child(1), & :nth-child(2)': {
      fontSize: '12px',
    },
  },
  fileList: {
    marginTop: theme.spacing(12),
  },
  fileItem: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily2,
    marginBottom: theme.spacing(1),
    background: 'none',
    maxWidth: '100%',
    display: 'block',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.text.primary,
    },
    '&:focus': {
      background: 'none',
    },
  },
}))

export type IFileData = {
  file: string
  size: number
  date: string
}

export type FileUploadProps = React.HTMLProps<HTMLInputElement> &
  TextFieldProps & {
    className?: string | ''
    id: string
    token?: string
    error?: string | boolean | false
    max?: number | 10
    maxFile?: number | 10
    accept?: string
    multiple?: boolean | false
  }

export default function FileUpload({
  token,
  max,
  maxFile,
  accept,
  multiple,
}: FileUploadProps): ReactElement {
  const classes = useStyles()
  const intl = useIntl()
  const [fileList, setFileList] = React.useState([])
  const [error, setError] = React.useState('')
  const [loading, setLoading] = useState(false)
  const { setCursorType } = useCustomCursor()

  const uploadAssetError = (e) => {
    if (e.response.status === 413) {
      const msg = intl
        .formatMessage({
          id: 'form.fileupload.maxFileSize',
        })
        .split('{maxFile}')
        .join(max)
      setError(msg)
    }
  }

  const onChangeHandler = ({ target }: any) => {
    setLoading(true)
    const headers = {
      'X-Token': token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    const formData = new FormData()
    formData.append('file', target.files[0])

    axios
      .post(`${process.env.GATSBY_JOB_SERVICE_URL}/api/attachment`, formData, {
        headers,
      })
      .then((response) => {
        updateFileList()
      })
      .catch(uploadAssetError)
      .finally(function () {
        setLoading(false)
        setCursorType('')
      })
  }

  const generateFileList = (data: Array<IFileData>) => {
    let size = 0
    for (let i = 0; i < data.length; i++) {
      size += data[i].size
    }
    if (size <= max * 1024 * 1024) {
      setError('')
      setFileList(data)
    } else {
      setError(
        intl
          .formatMessage({
            id: 'form.fileupload.maxFileSize',
          })
          .split('{max}')
          .join(max)
      )
    }
  }

  const updateFileList = () => {
    const config = {
      headers: {
        'X-Token': token,
      },
    }
    setLoading(false)
    axios
      .get(`${process.env.GATSBY_JOB_SERVICE_URL}/api/attachment`, config)
      .then((response) => {
        generateFileList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {
        setLoading(false)
      })
  }

  const handleDelete = (fileId = '') => {
    const config = {
      headers: {
        'X-Token': token,
      },
    }
    setLoading(true)
    axios
      .delete(
        `${process.env.GATSBY_JOB_SERVICE_URL}/api/attachment/${fileId}`,
        config
      )
      .then((response) => {
        updateFileList()
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {
        setLoading(false)
      })
  }

  let fileSize = 0
  for (let i = 0; i < fileList.length; i++) {
    fileSize += fileList[i].size / 1024 / 1024
  }

  useEffect(() => {
    updateFileList()
  }, [])

  return (
    <div className={classes.inputUploadRoot}>
      <input
        id="raised-button-file"
        accept={accept}
        className={classes.input}
        style={{ display: 'none' }}
        onChange={onChangeHandler}
        multiple={multiple}
        type="file"
      />
      <label htmlFor="raised-button-file" className={classes.label}>
        {!loading && (
          <div
            className={classes.iconMore}
            onMouseEnter={() => setCursorType('hide')}
            onMouseLeave={() => setCursorType('')}
          >
            <Icon name="More" />
          </div>
        )}
        {loading && <div className={classes.loading} />}
        <div className={classes.info}>
          <Typography variant="h3" component="p">
            {intl.formatMessage({
              id: 'form.fileupload.label',
            })}
          </Typography>
          {!fileList ||
            (fileList.length === 0 && (
              <Typography variant="body1" component="p">
                {intl
                  .formatMessage({ id: `form.fileupload.maxSizeAndFile` })
                  .split('{max}')
                  .join(max)
                  .split('{maxFile}')
                  .join(maxFile)}
              </Typography>
            ))}
          {error && error !== '' && (
            <FormHelperText spaced type="error">
              {error}
            </FormHelperText>
          )}
          {fileList && fileList.length > 0 && (
            <Typography variant="body1" component="p">
              {fileList.length}{' '}
              {intl.formatMessage({
                id: `form.fileupload.file${fileList.length > 1 ? 's' : ''}`,
              })}
              , {(Math.round((max - fileSize) * 100) * 0.01).toFixed(2)} MB{' '}
              {intl.formatMessage({ id: 'form.fileupload.left' })}
            </Typography>
          )}
        </div>
      </label>
      {fileList && fileList.length > 0 && (
        <div className={classes.fileList}>
          {fileList.map((item: IFileData, idx: number) => (
            <Chip
              className={classes.fileItem}
              key={`filitem-${idx}`}
              label={item.file}
              onDelete={() => handleDelete(item.file)}
              deleteIcon={<CloseIcon />}
            />
          ))}
        </div>
      )}
    </div>
  )
}
