import React, { ReactElement, useState, useEffect } from 'react'
import axios from 'axios'
import { HistoryLocation } from '@reach/router'

import MetaTags from '@components/modules/global/metatags'

import ApplicationForm from '@components/core/forms/applicationForm'
import NotFound from '@components/modules/content/M014-NotFound'

export interface JobPageProps {
  location: HistoryLocation
  pageContext: DBN.PageHelpers.PageContext
  params: {
    slug: string
  }
}

export default function Job({
  pageContext,
  params,
}: JobPageProps): ReactElement {
  const [job, setJob] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    axios
      .get(
        `${process.env.GATSBY_JOB_SERVICE_URL}/api/jobs/${
          params.slug
        }?lang=${pageContext.locale.toUpperCase()}`
      )
      .then((res) => {
        const job = res.data
        setJob(job)
      })
      .catch((err) => {
        console.log(err)
      })
      .then(() => {
        setLoaded(true)
      })
  }, [])

  return loaded ? (
    job !== null ? (
      <>
        <MetaTags
          locale={pageContext.locale}
          meta={{
            noIndex: true,
            metaTitle: `${job.name} | Scholz & Friends`,
            metaDescription: pageContext.page.metaTags.metaDescription,
            metaOgtitle: `${job.name} | Scholz & Friends`,
            metaOgdescription: pageContext.page.metaTags.metaOgdescription,
            metaOgimage: pageContext.page.metaTags.metaOgimage,
          }}
        />
        <ApplicationForm job={job} />
      </>
    ) : (
      <NotFound pageContext={pageContext} />
    )
  ) : (
    <></>
  )
}
