import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { InView } from 'react-intersection-observer'

import useCustomCursor from '@system/hooks/useCustomCursor'

import Headline from '@components/text/headline'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'
import Carousel from '@components/core/carousel'
import PageLink from '@components/core/pagelink'

const useStyles = makeStyles((theme) => ({
  logoWallRoot: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(18),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(40),
    },
  },
  logoWallHeadline: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(16),
    },
  },
  logoWallSlider: {
    position: 'relative',
  },
  logoWallSliderCarousel: {
    overflow: 'visible !important',
  },
  logoWallSliderContainer: {
    [theme.breakpoints.up('md')]: {
      pointerEvents: 'none',
    },
  },
  logoWallSliderWrapper: {
    marginRight: theme.spacing(6),
    '& $clientRoot': {
      opacity: 0,
      transform: 'translate(0, 40px)',
      transition: 'transform 0.6s ease-out, opacity 0.6s',
    },
  },
  logoWallSliderPrev: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '50%',
  },
  logoWallSliderNext: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '50%',
  },
  clientRoot: {
    display: 'block',
    width: '100%',
    maxWidth: '160px',
    '&:not(:first-child)': {
      marginTop: theme.spacing(14),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(22),
      },
    },
  },
  clientClickable: {
    pointerEvents: 'auto',
    '&:hover $clientImage': {
      transform: 'scale(1.1)',
      transition: 'transform 0.6s ease-in-out',
    },
  },
  clientImage: {
    pointerEvents: 'none',
    transition: 'transform 0.3s ease-out',
  },
  logoWallSliderWrapperFadeIn: {
    '& $clientRoot': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
  },
}))

export type ClientProps = {
  name?: string
  logo?: DBN.Contentful.IAsset
  showcase?: {
    fields: {
      fullPath: string
      isExternal: boolean
    }
  }
}

export type LogoWallProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  headline?: string
  clients?: Array<ClientProps>
}

export function Client({
  logo,
  showcase,
  ...props
}: ClientProps): ReactElement {
  const classes = useStyles()
  const gatsbyImage = logo ? getImage(logo) : null
  const { setCursorType } = useCustomCursor()
  const cursor = showcase?.fields
    ? showcase.fields?.isExternal
      ? 'teaserExternal'
      : 'teaserMore'
    : ''

  if (gatsbyImage == null) return <></>

  return showcase ? (
    <PageLink
      page={showcase}
      className={clsx(classes.clientRoot, classes.clientClickable)}
      {...props}
    >
      <div
        onMouseEnter={() => setCursorType(cursor)}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
      >
        <GatsbyImage
          image={gatsbyImage}
          alt={logo.description || ''}
          title={logo.title}
          objectFit="contain"
          className={classes.clientImage}
        />
      </div>
    </PageLink>
  ) : (
    <div className={classes.clientRoot} {...props}>
      {logo && gatsbyImage && (
        <GatsbyImage
          image={gatsbyImage}
          alt={logo.description || ''}
          title={logo.title}
          objectFit="contain"
        />
      )}
    </div>
  )
}

export function LogoWallMobile({
  headline,
  clients,
}: LogoWallProps): ReactElement {
  const classes = useStyles()
  const containerRef = useRef<HTMLDivElement>(null)
  const [slidesPerView, setSlidesPerView] = useState(1.4)

  useEffect(() => {
    const calcSlides = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        setSlidesPerView(containerRect.width / (containerRect.width - 64))
      }
    }

    calcSlides()

    window.addEventListener('resize', calcSlides)
    return () => {
      window.removeEventListener('resize', calcSlides)
    }
  }, [])

  const groups = clients
    ? clients
        .reduce(
          (groups, curr) => {
            const arr = groups[groups.length - 1]
            arr.push(curr)
            if (arr.length === 2) groups.push([])
            return groups
          },
          [[]]
        )
        .filter((chunk) => chunk.length)
    : null

  return groups ? (
    <>
      <Container type="nomargin">
        {headline && (
          <Headline level={21} className={classes.logoWallHeadline}>
            {headline}
          </Headline>
        )}
        {clients && (
          <div ref={containerRef}>
            <Carousel
              slidesPerView={slidesPerView}
              className={classes.logoWallSliderCarousel}
            >
              {groups.map((group: Array<ClientProps>, groupIndex: number) => (
                <div className={classes.logoWallSide} key={groupIndex}>
                  {group.map((client: ClientProps, clientIndex: number) => (
                    <Client
                      logo={client.logo}
                      showcase={client.showcase}
                      key={`$(groupIndex)-${clientIndex}`}
                    />
                  ))}
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </Container>
    </>
  ) : (
    <></>
  )
}

export function LogoWallDesktop({
  headline,
  clients,
}: LogoWallProps): ReactElement {
  const classes = useStyles()
  const [swiper, setSwiper] = useState(null)
  const [spaceBetween, setSpaceBetween] = useState(136)
  const { setCursorType } = useCustomCursor()
  const containerRef = useRef<HTMLDivElement>(null)
  const [touchStart, setTouchStart] = React.useState(0)
  const [touchEnd, setTouchEnd] = React.useState(0)

  const handleTouchStart = (e: TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX)
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    const diff = 50
    if (touchStart - touchEnd > diff) {
      if (swiper && !swiper.isEnd) swiper.slideNext()
    }

    if (touchStart - touchEnd < -diff) {
      if (swiper && !swiper.isBeginning) swiper.slidePrev()
    }
    setTouchStart(0)
    setTouchEnd(0)
  }

  useEffect(() => {
    const calcSpace = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        setSpaceBetween(Math.max((containerRect.width - 640 - 24) / 3, 32)) // 4* 160
      }
    }

    calcSpace()

    window.addEventListener('resize', calcSpace)
    return () => {
      window.removeEventListener('resize', calcSpace)
    }
  }, [])

  const groups = clients
    ? clients
        .reduce(
          (groups, curr) => {
            const arr = groups[groups.length - 1]
            arr.push(curr)
            if (arr.length === 2) groups.push([])
            return groups
          },
          [[]]
        )
        .filter((chunk) => chunk.length)
    : null

  return groups ? (
    <>
      {headline && (
        <Container type="nomargin">
          <Headline level={21} className={classes.logoWallHeadline}>
            {headline}
          </Headline>
        </Container>
      )}
      {clients && (
        <div
          className={classes.logoWallSlider}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className={classes.logoWallSliderPrev}
            onMouseEnter={() => {
              if (swiper && !swiper.isBeginning) {
                setCursorType('arrow-left')
              }
            }}
            onMouseLeave={() => setCursorType('')}
            onClick={() => {
              if (swiper && !swiper.isBeginning) {
                swiper.slidePrev()
                if (swiper.isBeginning) {
                  setCursorType('')
                }
              }
            }}
          ></div>
          <div
            className={classes.logoWallSliderNext}
            onMouseEnter={() => {
              if (swiper && !swiper.isEnd) {
                setCursorType('arrow-right')
              }
            }}
            onMouseLeave={() => setCursorType('')}
            onClick={() => {
              if (swiper && !swiper.isEnd) {
                swiper.slideNext()
                if (swiper.isEnd) {
                  setCursorType('')
                }
              }
            }}
          ></div>
          <Container
            type="nomargin"
            className={classes.logoWallSliderContainer}
          >
            <InView threshold={0} triggerOnce={true} delay={100}>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <div
                    className={clsx(classes.logoWallSliderWrapper, {
                      [classes.logoWallSliderWrapperFadeIn]: inView,
                    })}
                    ref={containerRef}
                  >
                    <Carousel
                      spaceBetween={spaceBetween}
                      slidesPerView={4}
                      className={classes.logoWallSliderCarousel}
                      onSwiper={setSwiper}
                    >
                      {groups.map(
                        (group: Array<ClientProps>, groupIndex: number) => (
                          <div
                            className={classes.logoWallSide}
                            key={groupIndex}
                          >
                            {group.map(
                              (client: ClientProps, clientIndex: number) => (
                                <Client
                                  logo={client.logo}
                                  showcase={client.showcase}
                                  key={`$(groupIndex)-${clientIndex}`}
                                  style={{
                                    transitionDelay: `${
                                      groupIndex + clientIndex * 5
                                    }00ms`,
                                  }}
                                />
                              )
                            )}
                          </div>
                        )
                      )}
                    </Carousel>
                  </div>
                </div>
              )}
            </InView>
          </Container>
        </div>
      )}
    </>
  ) : (
    <></>
  )
}

export default function LogoWall({
  theme,
  anchor,
  headline,
  clients,
}: LogoWallProps): ReactElement {
  const classes = useStyles()
  const [mobile, setMobile] = useState(true)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')

    function mediaHandler(this: MediaQueryList) {
      if (this.matches) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }

    if (mediaQuery.matches) {
      setMobile(false)
    }

    mediaQuery.addListener(mediaHandler)
    return () => {
      mediaQuery.removeListener(mediaHandler)
    }
  })

  return (
    <Module theme={theme} anchor={anchor} className={classes.logoWallRoot}>
      {mobile ? (
        <LogoWallMobile headline={headline} clients={clients} />
      ) : (
        <LogoWallDesktop headline={headline} clients={clients} />
      )}
    </Module>
  )
}
