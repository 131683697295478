import { createTheme as createThemeMUI, Theme } from '@material-ui/core/styles'

import Breakpoints from './breakpoints'
import Palette from './palette'
import Container from './container'
import CustomShadows from './shadows'
import Typography from './typography'
import OverridesFunction from './overrides'
import Mixins from './mixins'

export default function createTheme(): Theme {
  const theme = createThemeMUI({
    breakpoints: Breakpoints,
    palette: Palette,
    spacing: 4,
    shadows: CustomShadows,
    typography: {
      fontFamily: 'Circular Bold, Helvetica, Arial, sans-serif',
      fontFamily1: 'Circular Medium, Helvetica, Arial, sans-serif',
      fontFamily2: 'Circular Book, Helvetica, Arial, sans-serif',
    },
    container: Container,
  })
  const typography = Typography(theme)
  const overrides = OverridesFunction(theme)
  const mixins = Mixins(theme)

  theme.typography = { ...theme.typography, ...typography }
  theme.overrides = { ...theme.overrides, ...overrides }
  theme.mixins = { ...theme.mixins, ...mixins }

  theme.themeName = 'DB-N'

  return theme
}
