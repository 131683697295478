import React, { ReactElement } from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from './helperText'
import TextInput from './textInput'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginBottom: theme.spacing(8),
    width: '100%',
    '& .input': {
      width: '100%',
      maxWidth: '520px',
    },
    '&.error label': {
      color: '#ff0000',
    },
  },
  inputlabel: {
    ...theme.typography.label,
    padding: theme.spacing(0, 5, 1, 0),
    color: 'inherit',
    width: '100%',
  },
}))

export type TextAreaProps = {
  label: string
  infoText?: string | undefined
  id: string
  name: string
  type: string
  variant?: string | undefined
  placeholder?: string | undefined
  autoComplete?: string | undefined
  rowsMax?: string | number | undefined
}

export default function TextArea(props: TextAreaProps): ReactElement {
  const { label, infoText, id, name, type, rowsMax, ...other } = props
  const classes = useStyles()
  const [field, meta] = useField(name)

  return (
    <div
      className={clsx(classes.formGroup, {
        error: meta.touched && meta.error,
      })}
    >
      {label && (
        <InputLabel className={classes.inputlabel} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <TextInput
        id={id}
        className="input"
        type={type}
        multiline={true}
        rows={12}
        rowsMax={12}
        outlined={true}
        error={meta.touched && meta.error}
        {...field}
        {...other}
      />
      {infoText && (
        <FormHelperText spaced type="info">
          {infoText}
        </FormHelperText>
      )}
      {meta.touched && meta.error ? (
        <FormHelperText spaced type="error">
          {meta.error}
        </FormHelperText>
      ) : null}
    </div>
  )
}
